<div
	class="ui-time-input"
	[ngClass]="{
		'ui-time-input--error': !focused && formControl.invalid && formControl.touched,
		'ui-time-input--valid': !focused && formControl.valid && showWhenValid && formControl.value,
		'ui-time-input--disabled': formControl.disabled,
	}"
>
	<div class="ui-time-input__label">
		<ng-content select="[label]"></ng-content>
	</div>

	<div
		class="ui-time-input__inputWrapper"
		uiDropdown
		[ignoreTrigger]="true"
		(openChange)="onDropdownStateChange($event)"
		#dropdown
	>
		<div class="ui-time-input__input" [ngClass]="{ 'is-focused': focused }" uiDropdownTrigger>
			<input
				type="text"
				class="ui-time-input__inputField"
				[formControl]="formControl"
				[placeholder]="placeholder"
				(focusout)="onFocusOut()"
				(focusin)="focused = true"
				[id]="'input-time-' + id"
				#inputElement
			/>
			<div class="ui-time-input__icons">
				<span (click)="openDropdown()">
					@if (showWhenValid) {
						<!-- Failure Icon | When the input is not valid -->
						@if (formControl.invalid && formControl.value) {
							<ui-icon icon="failures" type="error" class="ui-time-input__icon" size="s"> </ui-icon>
						}
						<!-- Success Icon | When the input is valid -->
						@if (formControl.valid && formControl.value) {
							<ui-icon icon="x_check" type="success" class="ui-time-input__icon" size="s">
							</ui-icon>
						}
					} @else {
						<ui-icon icon="clock" class="ui-time-input__icon" size="s"> </ui-icon>
					}
				</span>
			</div>
		</div>
		<ng-container *uiDropdownContent>
			<div class="p-4 flex gap-2 justify-center items-center">
				<div class="flex gap-2 flex-col">
					<ui-icon
						icon="x_arrow_up"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('hours', 'up')"
					></ui-icon>
					<span class="select-none">
						{{ formControl.value ? formControl.value.split(':')[0] : '00' }}
					</span>
					<ui-icon
						icon="x_arrow_down"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('hours', 'down')"
					></ui-icon>
				</div>
				<span class="select-none">:</span>
				<div class="flex gap-2 flex-col">
					<ui-icon
						icon="x_arrow_up"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('minutes', 'up')"
					></ui-icon>
					<span class="select-none">
						{{ formControl.value ? formControl.value.split(':')[1] : '00' }}
					</span>
					<ui-icon
						icon="x_arrow_down"
						size="s"
						[action]="true"
						(iconClicked)="setTimeViaArrows('minutes', 'down')"
					></ui-icon>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="ui-time-input__bottomText">
		<ng-content select="[bottomText]"></ng-content>
	</div>
</div>
