import { App, AppMenuPoints, RedirectService } from '@agilox/common';
import { AppMenuPoint, NavColor, NavColors } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';

@Component({
	selector: 'ui-mobile-app-nav',
	templateUrl: './mobile-app-nav.component.html',
	styleUrls: ['./mobile-app-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAppNavComponent {
	@Input() set apps(apps: Array<AppMenuPoint> | undefined) {
		if (!apps) {
			apps = AppMenuPoints;
		}

		this.myApp = apps.find((app) => app.name === 'my') ?? AppMenuPoints[0];
		this._apps = apps.filter((app) => app.name !== 'my');
	}
	private _apps: Array<AppMenuPoint> = [];
	get apps(): Array<AppMenuPoint> {
		return this._apps;
	}
	@Input() activeApp: AppMenuPoint | undefined;
	@Input() environment: string = 'live';
	@Input() navColor: NavColor = NavColors.Dark;
	@Input() open: boolean = false;

	@Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

	myApp: AppMenuPoint = AppMenuPoints[0];

	private redirectService: RedirectService = inject(RedirectService);

	goToApp(app: AppMenuPoint) {
		this.redirectService.redirectToApp(app.name, this.environment);
	}

	toggle() {
		this.open = !this.open;
		this.toggled.emit(this.open);
	}

	@HostListener('window: click')
	@HostListener('window:keydown.escape')
	clickOutside() {
		if (this.open) {
			this.open = false;
		}
	}

	/**
	 * prevent the event when click inside the popup
	 * @param evt event
	 */
	clickInside(evt: Event) {
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();
	}
}
