<div class="app" [class]="pageName">
	<div class="app__container">
		<ui-nav
			class="hide-mobile"
			[items]="desktopMenuPoints"
			[apps]="appNavMenuPoints"
			[activeApp]="activeApp"
			[environment]="auth.environment"
			[beta]="true"
			navColor="dark"
			(callbackEvent)="navCallback($event)"
		></ui-nav>
		<ui-mobile-nav
			class="hide-desktop"
			[items]="mobileMenuPoints"
			[apps]="appNavMenuPoints"
			[activeApp]="activeApp"
			[environment]="auth.environment"
			(callbackEvent)="navCallback($event)"
			navColor="dark"
		></ui-mobile-nav>
		<div [class]="pageName" class="app__page">
			<router-outlet></router-outlet>
			@if (error$ | async; as error) {
				<ui-overlay
					(closeOverlay)="onClose()"
					[showCloseButton]="true"
					[header]="error.type + '.header' | lowercase | translate"
				>
					<ui-error-content [error]="error" (closed)="onClose()"></ui-error-content>
				</ui-overlay>
			}
		</div>
	</div>
</div>
@if (helpOpen) {
	<ui-overlay [header]="'help_overlay.title' | translate" (closeOverlay)="onCloseHelp()">
		<ui-help-content (closed)="onCloseHelp()"> </ui-help-content>
	</ui-overlay>
}
