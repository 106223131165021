<div
	class="ui-select"
	[class.is-open]="dropdownOpen"
	[class.is-disabled]="formControl.disabled"
	data-cy="select-wrapper"
>
	<div
		class="ui-select__inner"
		uiDropdown
		[fullWidth]="fullDropdownWidth"
		(openChange)="onDropdownStateChange($event)"
	>
		<div class="ui-select__label">
			<ng-content select="[label]"></ng-content>
		</div>
		<ui-select-input
			[placeholder]="placeholder | translate"
			[hasError]="formControl.invalid && formControl.touched"
			[disabled]="formControl.disabled"
			[opened]="dropdownOpen"
			[value]="optionsFormControl.value | selectInputText"
			[amountSelected]="multiple ? optionsFormControl.value?.length : 1"
			[uiDropdownDisabled]="formControl.disabled"
			[showClearButton]="!multiple && clearEnabled && optionsFormControl.value"
			[inputId]="inputId"
			(clearSelection)="clearSingleSelection()"
			uiDropdownTrigger
			data-cy="select-dropdown-trigger"
		>
		</ui-select-input>

		<ng-container *uiDropdownContent>
			<ui-select-search
				*ngIf="searchEnabled"
				[placeholder]="'general.search' | translate"
				(searched)="onSearch($event)"
			></ui-select-search>

			<ui-select-option-list
				*ngIf="filteredOptions() as filteredOptions"
				[options]="filteredOptions"
				[multiEnabled]="multiple"
				(selected)="writeValue($event); optionSelected($event)"
				(scrollEnd)="onScrollEnd()"
				[selectedValues]="multiple ? savedValues : optionsFormControl.value ?? []"
				[customOptionTemplate]="customOptionTemplate"
				[groupSelectable]="groupSelectable"
				[customCompareFn]="compareFn"
				[fieldToCompareBy]="fieldToCompare"
				[fullWidth]="fullDropdownWidth"
			>
			</ui-select-option-list>

			@if (multiple) {
				<div class="ui-select__buttons">
					@if (savedValues.length > 0) {
						<ui-select-button
							class="ui-select__button"
							[label]="'select.deselect_all' | translate"
							(buttonClicked)="deselectAll()"
							[attr.data-cy]="'select-button-deselect-all'"
						>
						</ui-select-button>
					} @else if (savedValues.length === 0 && selectAllEnabled) {
						<ui-select-button
							class="ui-select__button"
							[label]="'select.select_all' | translate"
							(buttonClicked)="selectAll()"
						>
						</ui-select-button>
					}
					<ui-select-button
						class="ui-select__button"
						[label]="'general.save' | translate"
						(buttonClicked)="onSave()"
						[attr.data-cy]="'select-button-save'"
					>
					</ui-select-button>
				</div>
			}
		</ng-container>
		<div class="ui-select__bottomText">
			<ng-content select="[bottomText]"></ng-content>
		</div>
	</div>
</div>
