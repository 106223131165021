import { AppMenuPoint } from '@agilox/ui-common';
import { App } from '../enums/app.enum';
import { AppColors } from './app-colors.const';
import { AppRoles } from './app-roles.const';
import { image } from 'd3';

export const AppMenuPoints: AppMenuPoint[] = [
	{
		name: App.my,
		image: 'assets/images/logo-world.svg',
		imageSmall: 'assets/images/pikto-world.svg',
		roles: AppRoles[App.my],
		color: AppColors[App.my],
	},
	{
		name: App.analytics,
		image: 'assets/images/logo-analytics.svg',
		imageSmall: 'assets/images/pikto-analytics.svg',
		roles: AppRoles[App.analytics],
		color: AppColors[App.analytics],
	},
	{
		name: App.academy,
		image: 'assets/images/logo-academy.svg',
		imageSmall: 'assets/images/pikto-academy.svg',
		beta: true,
		roles: AppRoles[App.academy],
		color: AppColors[App.academy],
	},
	{
		name: App.docs,
		image: 'assets/images/logo-docs.svg',
		imageSmall: 'assets/images/pikto-docs.svg',
		roles: AppRoles[App.docs],
		color: AppColors[App.docs],
	},
	{
		name: App.lifecycle,
		image: 'assets/images/logo-lifecycle.svg',
		imageSmall: 'assets/images/pikto-lifecycle.svg',
		roles: AppRoles[App.lifecycle],
		color: AppColors[App.lifecycle],
	},
	{
		name: App.selftest,
		image: 'assets/images/logo-selftest.svg',
		imageSmall: 'assets/images/pikto-selftest.svg',
		roles: AppRoles[App.selftest],
		color: AppColors[App.selftest],
	},
];
