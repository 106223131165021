<div class="ui-mobile-app-nav" (click)="clickInside($event)" [class.is-open]="open">
	<div class="ui-mobile-app-nav__trigger" (click)="toggle()">
		<ui-icon icon="menu_bento" size="l" [type]="navColor"></ui-icon>
		<div class="ui-mobile-app-nav__triggerText ui-mobile-app-nav__triggerText--{{ navColor }}">
			{{ 'general.apps' | translate }}
		</div>
	</div>

	<div class="ui-mobile-app-nav__apps" *ngIf="open">
		<div
			class="ui-mobile-app-nav__myApp"
			(click)="goToApp(myApp)"
			[style.background-color]="activeApp?.name === myApp.name ? myApp.color?.background : ''"
		>
			<ng-container>
				<img alt="logo-my" [src]="myApp.image" class="ui-mobile-app-nav__logoWorld" />
			</ng-container>
		</div>
		<div class="ui-mobile-app-nav__divider">
			<ui-divider></ui-divider>
		</div>
		<ng-container *ngFor="let app of apps">
			<div
				class="ui-mobile-app-nav__app"
				(click)="goToApp(app)"
				[style.background-color]="activeApp?.name === app?.name ? app.color?.background : ''"
			>
				<img
					[alt]="'logo-' + app.name"
					[src]="app.imageSmall"
					class="ui-mobile-app-nav__logo ui-mobile-app-nav__logoSmall"
				/>
				<div class="ui-mobile-app-nav__appName">{{ 'apps.' + app.name | translate }}</div>

				@if (app.beta) {
					<ui-tag [text]="'general.beta' | translate" type="dark"></ui-tag>
				}
			</div>
		</ng-container>
	</div>
</div>
