import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class RouterExtraStateService {
	constructor(private router: Router) {}

	/**
	 * get extra state
	 * @param property property
	 */
	getExtraState(property: string): any {
		const state = this.router.getCurrentNavigation()?.extras?.state;
		let result = '';
		if (state !== undefined) {
			result = state[property];
			// for example, if the user comes from the dashboard and clicks on an interactive chart,
			// the router may lose the extras state
		} else if (window.history.state[property] !== undefined) {
			result = window.history.state[property];
		}
		return result === undefined || result === '' ? undefined : result;
	}
}
