<div class="ui-datepicker" [class.ui-datepicker--inline]="type === Datepickers.INLINE">
	<div class="ui-datepicker__label">
		<ng-content select="[label]"></ng-content>
	</div>
	@if (type === Datepickers.INLINE) {
		<div class="ui-datepicker__inline">
			<div class="ui-datepicker__base">
				<ui-datepicker-base
					[currentlyDisplayedMonth]="currentlyDisplayedMonth"
					[disabled]="formControl.disabled"
					[selectedDate]="formControl.value"
					(navDateChange)="onNavigationChange($event)"
					(dateSelectionChange)="onDateSelectionChange($event)"
					[minDate]="min"
					[maxDate]="max"
					[useMinDateAsStartDate]="useMinDateAsStartDate"
				></ui-datepicker-base>
			</div>
		</div>
	} @else {
		<ui-datepicker-input
			[placeholder]="placeholder"
			[formControl]="formControl"
			[minDate]="min"
			[maxDate]="max"
			[showWhenValid]="showWhenValid"
			[useMinDateAsStartDate]="useMinDateAsStartDate"
			[currentlyDisplayedMonth]="currentlyDisplayedMonth"
			[inputId]="inputId"
		>
		</ui-datepicker-input>
	}
	<div class="ui-datepicker__bottomText">
		<ng-content select="[bottomText]"></ng-content>
	</div>
</div>
