import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'ui-select-input',
	templateUrl: './select-input.component.html',
	styleUrls: ['./select-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SelectInputComponent {
	@Input() inputId: string = '';
	@Input() placeholder: string = '';
	@Input() disabled: boolean = false;
	@Input() hasError: boolean = false;
	@Input() opened: boolean = false;
	@Input() showWhenValid: boolean = false;
	@Input() value: string = '';
	@Input() amountSelected: number = 0;
	@Input() showClearButton: boolean = false;
	@Output() clearSelection: EventEmitter<void> = new EventEmitter<void>();

	onClearSelection($event: any): void {
		$event.stopImmediatePropagation();
		$event.preventDefault();
		this.clearSelection.emit();
	}
}
