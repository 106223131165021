import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	inject,
	Input,
	Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'ui-checkbox',
	standalone: true,
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	@Input() label: string = '';
	@Input() icon: 'checkmark' | 'minus' = 'checkmark';
	@Input() set disabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	@Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	public formControl: FormControl = new FormControl();

	@Input() set checked(checked: boolean) {
		this.formControl.setValue(checked);
		this.cdRef.markForCheck();
	}

	get checked(): boolean {
		return this.formControl.value;
	}

	onChange: any = () => {};
	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeEventValue(event: Event): void {
		this.writeValue((event.target as HTMLInputElement).checked);
	}

	writeValue(val: any): void {
		this.checked = val;
		this.cdRef.markForCheck();
		this.checkedChange.emit(this.checked);
		this.onChange(this.formControl.value);
		this.onTouched();
	}

	setDisabledState(isDisabled: boolean): void {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });
	}
}
