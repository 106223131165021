import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Pills, PillType } from '@agilox/ui-common';

@Component({
	selector: 'ui-pill',
	standalone: true,
	templateUrl: './pill.component.html',
	styleUrls: ['./pill.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent {
	@Input() value: string | undefined;
	@Input() type: PillType = Pills.SecondaryFilled;
	@Input() disabled: boolean = false;
}
