<ui-button
	[text]="
		(notificationsCount$ | async) ?? 0 > 0
			? ('notifications.open' | translate) + ' (' + (notificationsCount$ | async) + ')'
			: ''
	"
	(buttonClicked)="openNotifications()"
	type="blue"
	[hideTextOnMobile]="((notificationsCount$ | async) ?? 0) > 0"
	[inverted]="(notificationsCount$ | async) === 0"
	[disabled]="(hasNotifications$ | async) === false"
	icon="megaphone"
	data-cy="notification-button"
></ui-button>
