import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * This component wraps the navigation and the calendar
 * Is used both by the INLINE and the INPUT datepicker variants
 */
@Component({
	selector: 'ui-datepicker-base',
	templateUrl: './datepicker-base.component.html',
	styleUrls: ['./datepicker-base.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerBaseComponent {
	@Input({ required: true }) currentlyDisplayedMonth!: Date;
	@Input() minDate: Date | undefined;
	@Input() maxDate: Date | undefined;
	@Input() disabled: boolean = true;
	@Input() selectedDate: string | null = null;
	@Input() useMinDateAsStartDate: boolean = false;

	@Output() dateSelectionChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() navDateChange: EventEmitter<Date> = new EventEmitter<Date>();
}
