<div class="ui-box-content">
	<div class="ui-box-content__header">
		<ui-headline type="h4" class="ui-box-content__headline" [ellipsis]="true">{{
			title
		}}</ui-headline>
		@if (tagText) {
			<ui-tag class="ui-box-content__tag" [text]="tagText" type="dark"></ui-tag>
		}
	</div>

	<div class="ui-box-content__meta">
		@for (item of contentBoxItems; track item) {
			<div class="ui-box-content__metaItem" [class.is-disabled]="item.disabled">
				<ui-icon
					[icon]="item.icon"
					size="m"
					class="ui-box-content__metaIcon"
					[class.is-disabled]="item.disabled"
					[type]="item.disabled ? 'light' : 'dark'"
				>
				</ui-icon>
				{{ item.text | translate: item.textParams }}
			</div>
		}
	</div>

	<div class="ui-box-content__bottom">
		@if (showDownloadButton) {
			<ui-button icon="download" type="secondary" (buttonClicked)="onDownloadClicked()"></ui-button>
		}
		@if (buttonText) {
			<ui-button
				class="ui-box-content__button"
				[text]="buttonText | translate"
				(click)="onButtonClicked()"
				[type]="buttonType"
				[color]="color"
				[disabled]="disabled"
			>
			</ui-button>
		}
	</div>
</div>
