<div class="ui-download-icon">
	@if (status === DownloadStatus.PENDING) {
		<ui-spinner
			[inline]="true"
			[small]="true"
			[uiTooltip]="'download.pending' | translate"
		></ui-spinner>
	} @else if (status === DownloadStatus.SUCCESS) {
		<ui-icon
			icon="x_check"
			size="l"
			type="primary"
			[uiTooltip]="'download.success' | translate"
		></ui-icon>
	} @else if (status === DownloadStatus.ERROR) {
		<ui-icon
			icon="failures"
			size="l"
			type="error"
			[uiTooltip]="'download.error' | translate"
		></ui-icon>
	} @else {
		<ui-icon
			icon="download"
			size="l"
			type="dark"
			[action]="true"
			[uiTooltip]="iconTooltip ? ('general.download' | translate) : ''"
			(iconClicked)="iconClicked.emit($event)"
		></ui-icon>
	}
</div>
