import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	inject,
	Input,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioButton } from '@agilox/ui-common';
import { NgForOf } from '@angular/common';

@Component({
	selector: 'ui-radio',
	standalone: true,
	imports: [NgForOf],
	templateUrl: './radio.component.html',
	styleUrls: ['./radio.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioComponent),
			multi: true,
		},
	],
})
export class RadioComponent implements ControlValueAccessor {
	@Input() disabled: boolean = false;

	@Input() radioButtons: Array<RadioButton> = [];

	/**
	 * Name of the radio button group
	 * @type {string}
	 * @description Needed to make sure only one radio button is selected at a time.
	 * A possible value would be the name of the form control.
	 */
	@Input() groupName: string = '';

	formControl: FormControl = new FormControl();

	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	onTouched = () => {};

	onChange = (item: RadioButton) => {
		if (!this.disabled && !item.disabled) {
			this.writeValue(item.value);
		}
	};
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(val: any) {
		this.formControl.setValue(val, { emitEvent: false });
		this.onTouched();
		this.cdRef.markForCheck();
	}

	isChecked(value: any): boolean {
		return this.formControl.value === value;
	}
}
