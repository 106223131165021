import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { HeadlineComponent } from '../headline/headline.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';
import { NgIf } from '@angular/common';
import { offcanvasAnimations } from './offcanvas.animations';

@Component({
	selector: 'ui-offcanvas',
	standalone: true,
	imports: [HeadlineComponent, TranslateModule, IconModule, NgIf],
	templateUrl: './offcanvas.component.html',
	styleUrls: ['./offcanvas.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: offcanvasAnimations,
})
export class OffcanvasComponent {
	/**
	 * reference to the offcanvas element
	 * needed in the isChildOfSpecificElement function
	 */
	@ViewChild('offcanvasElement', { static: false }) offcanvasElement: ElementRef | undefined;

	@Input() open: boolean = false;
	@Input() closeOnOutsideClick: boolean = false;
	@Input() header: string = '';

	/**
	 * if true, the close icon will be shown
	 */
	@Input() showCloseIcon: boolean = true;

	/**
	 * Event emitted when the offcanvas is closed
	 */
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Element that opened the offcanvas
	 * Needed for the clickOutside function
	 * If not set, the clickOutside function will not work as it will immediately close again
	 * when the offcanvas is opened
	 */
	elementOpened: HTMLElement | undefined;

	/**
	 * Listens to click events on the document and toggles the open state of the offcanvas and emits the openChange event if the click is outside the offcanvas
	 * and closeOnOutsideClick is true
	 * @param event
	 */
	@HostListener('document:click', ['$event'])
	clickOutside(event: Event) {
		if (!this.elementOpened) {
			this.elementOpened = event.target as HTMLElement;
			return;
		} else if (
			this.closeOnOutsideClick &&
			this.open &&
			!this.isChildOfSpecificElement(
				event.target as HTMLElement,
				this.offcanvasElement?.nativeElement
			)
		) {
			this.close();
		}
	}

	/**
	 * checks if the given element is a child of the given parent element
	 * @param childElement
	 * @param parentElement
	 * @returns true if the child element is a child of the parent element
	 *         false if the child element is not a child of the parent element
	 */
	isChildOfSpecificElement(
		childElement: HTMLElement,
		parentElement: HTMLElement | null | undefined
	): boolean {
		if (parentElement) {
			let currentElement: HTMLElement = childElement;
			while (currentElement) {
				if (currentElement === parentElement) {
					return true;
				} else if (!currentElement.parentElement) {
					return false;
				} else {
					currentElement = currentElement.parentElement;
				}
			}
			return false;
		} else {
			return false;
		}
	}

	/**
	 * toggles the open state of the offcanvas and emits the openChange event
	 */
	close() {
		this.elementOpened = undefined;
		this.open = false;
		this.closed.emit();
	}
}
