export interface Vehicle {
	serial: string;
	model: string;
	type: string | null;
	name: string | null;
	azure: boolean;
	union: string | null;
	collective: string | null;
	customer: string;
	street: string;
	zip: string;
	city: string;
	country: string;
	subscription_end: number;
	timezone: string;
	maas: boolean;
	unionUuid: string;
}
