import { dateToString } from '@agilox/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import { DatepickerDate } from '../../models/datepicker-date.interface';

@Component({
	selector: 'ui-datepicker-calendar',
	templateUrl: './datepicker-calendar.component.html',
	styleUrls: ['./datepicker-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCalendarComponent {
	@Input() minDate: Date | undefined = undefined;
	@Input() maxDate: Date | undefined = undefined;
	@Input({ required: true }) disabled!: boolean;
	@Input() currentSelection: string | null = null;
	@Input() useMinDateAsStartDate: boolean = false;

	private _currentlyDisplayedMonth: Date = new Date();

	@Input() set currentlyDisplayedMonth(value: Date | null) {
		this._currentlyDisplayedMonth = value ? value : new Date();
		this.setDays();
	}
	get currentlyDisplayedMonth(): Date {
		return this._currentlyDisplayedMonth;
	}

	@Output() dateSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

	@Output() monthChange: EventEmitter<Date> = new EventEmitter<Date>();

	days: DatepickerDate[] = [];

	weekDays: string[] = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

	currentlyHovered: DatepickerDate | null = null;

	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	private setDays() {
		this.days = this.generateMonth(
			this.currentlyDisplayedMonth.getFullYear(),
			this.currentlyDisplayedMonth.getMonth() + 1
		);
		this.cdRef.markForCheck();
	}

	/**
	 * Generates the days for a specific month
	 * Takes care of the offset for the first day of the month
	 * and the days of the previous and next month
	 * @param year
	 * @param month
	 * @private
	 */
	private generateMonth(year: number, month: number): DatepickerDate[] {
		const numberOfDays: number = new Date(year, month, 0).getDate();
		const firstDay: number = new Date(year, month - 1, 1).getDay();
		const offset = firstDay === 0 ? 6 : firstDay - 1;

		const previousMonthDays: number = (7 + offset) % 7;
		const nextMonthDays: number = (7 - new Date(year, month - 1, numberOfDays).getDay()) % 7;

		const previousMonth: number = month === 1 ? 12 : month - 1;
		const previousYear: number = month === 1 ? year - 1 : year;
		const nextMonth: number = month === 12 ? 1 : month + 1;
		const nextYear: number = month === 12 ? year + 1 : year;

		let monthDays: DatepickerDate[] = this.generatePreviousMonth(
			previousMonthDays,
			previousYear,
			previousMonth,
			year,
			month
		);

		for (let i: number = 1; i <= numberOfDays; i++) {
			const day: Date = new Date(year, month - 1, i);
			monthDays.push({ date: dateToString(day), disabled: false });
		}

		for (let i: number = 1; i <= nextMonthDays; i++) {
			const day: Date = new Date(nextYear, nextMonth - 1, i);
			monthDays.push({
				date: dateToString(day),
				notInActiveMonth: true,
			});
		}
		return this.disableDays(monthDays);
	}

	private generatePreviousMonth(
		previousMonthDays: number,
		previousYear: number,
		previousMonth: number,
		year: number,
		month: number
	) {
		let monthDays: DatepickerDate[] = [];
		for (let i = 0; i < previousMonthDays; i++) {
			const day: Date = new Date(
				previousYear,
				previousMonth - 1,
				new Date(year, month - 1, 0).getDate() - previousMonthDays + i + 1
			);
			monthDays.push({
				date: dateToString(day),
				notInActiveMonth: true,
			});
		}
		return monthDays;
	}

	private disableDays(days: DatepickerDate[]): DatepickerDate[] {
		return days.map((day: DatepickerDate) => {
			if (this.minDate && new Date(day.date) < this.minDate) {
				day.disabled = true;
			}
			if (this.maxDate && new Date(day.date) > this.maxDate) {
				day.disabled = true;
			}
			if (this.disabled) {
				day.disabled = true;
			}
			return day;
		});
	}

	isDateSelected(day: DatepickerDate): boolean {
		if (this.useMinDateAsStartDate && this.minDate) {
			return day.date === this.minDateToStartString() || day.date === this.currentSelection;
		}
		return day.date === this.currentSelection;
	}

	/**
	 * If there is a minDate set and the useMinDateAsStartDate is true
	 * We need to subtract one day and convert it to a string
	 * @private
	 */
	private minDateToStartString(): string {
		if (this.minDate) {
			const minDate: Date = new Date(this.minDate);
			minDate.setDate(minDate.getDate() - 1);
			return minDate.toISOString().slice(0, 10);
		}
		return '';
	}

	isHovered(day: DatepickerDate): boolean {
		if (!this.currentSelection || day.disabled || !this.useMinDateAsStartDate) {
			return false;
		}
		const minDateCheck: boolean = this.compareDate(day.date, this.minDateToStartString(), '>');
		let currentlyHoveredCheck: boolean = false;
		let currentSelectionCheck: boolean = false;
		if (this.currentlyHovered) {
			currentlyHoveredCheck = this.compareDate(day.date, this.currentlyHovered.date, '<');
		} else {
			if (this.currentSelection) {
				currentSelectionCheck = this.compareDate(day.date, this.currentSelection, '<');
			}
		}

		return minDateCheck && (currentlyHoveredCheck || currentSelectionCheck);
	}

	private compareDate(date1: string, date2: string, operator: '>' | '<' = '<') {
		const d1: Date = new Date(date1);
		const d2: Date = new Date(date2);
		if (operator === '<') {
			return d1 < d2;
		} else {
			return d1 > d2;
		}
	}

	selectDay(day: DatepickerDate) {
		if (day.disabled || this.disabled) {
			return;
		}
		if (day.notInActiveMonth) {
			this.monthChange.emit(new Date(day.date));
			return;
		}

		this.dateSelectionChanged.emit(day.date);
	}

	/**
	 * These functions are used to handle the hover effect for the range selection
	 */
	hoverDay(day: DatepickerDate) {
		if (!day.disabled && !this.disabled) {
			this.currentlyHovered = day;
		}
	}
	unhoverDay() {
		this.currentlyHovered = null;
	}
}
