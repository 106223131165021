import { Position, Positions } from '@agilox/ui-common';
import {
	AfterViewInit,
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	ContentChild,
	Directive,
	ElementRef,
	EmbeddedViewRef,
	EventEmitter,
	HostListener,
	Injector,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DropdownComponent } from '../component/dropdown/dropdown.component';
import { DropdownContentDirective } from './dropdown-content.directive';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';

@Directive({
	selector: '[uiDropdown]',
})
export class DropdownDirective implements AfterViewInit, OnDestroy {
	@Input() closeOnOutsideClick: boolean = true;

	@Input() fullWidth: boolean = false;

	@Input() dropdownPosition: Position = Positions.Bottom;

	/** When the opening should only be triggered programmatically **/
	@Input() ignoreTrigger: boolean = false;

	@Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	/* prettier-ignore */
	@ContentChild(DropdownTriggerDirective, { static: false }) dropdownTrigger: DropdownTriggerDirective | null = null;
	/* prettier-ignore */
	@ContentChild(DropdownContentDirective, { static: false }) dropdownContent: DropdownContentDirective | null = null;

	private _dropdownRef: ComponentRef<DropdownComponent> | null = null;
	private _dropdownOpenControl: FormControl = new FormControl(false);
	_dropdownOpenControlSubscription$: Subscription | null = null;

	private clickListenerSubscription: Subscription | null = null;

	constructor(
		public componentFactoryResolver: ComponentFactoryResolver,
		public applicationRef: ApplicationRef,
		private injector: Injector,
		public elementRef: ElementRef
	) {}

	ngAfterViewInit() {
		if (this.dropdownTrigger) {
			this.dropdownTrigger.ignoreTrigger = this.ignoreTrigger;
			this._dropdownOpenControl.setValue(false);
			this.dropdownTrigger.dropdownControl = this._dropdownOpenControl;
			this._dropdownOpenControlSubscription$ = this._dropdownOpenControl.valueChanges.subscribe(
				(open) => {
					this.openChange.emit(open);
					if (open) {
						this.initDropdown();
					} else {
						this.destroyDropdown();
					}
				}
			);
		}
	}

	/**
	 * Create the dropdown component
	 * @description
	 * 1. Create the dropdown component
	 * 2. Attach the dropdown component to the applicationRef
	 * 3. Get the dom element from the dropdown component
	 * 4. Append the dom element to the host element
	 * 5. Set the properties of the dropdown component
	 * @returns void
	 */
	initDropdown() {
		const factory = this.componentFactoryResolver.resolveComponentFactory(DropdownComponent);
		this._dropdownRef = factory.create(this.injector);
		this.applicationRef.attachView(this._dropdownRef.hostView);
		const [domElem] = (this._dropdownRef.hostView as EmbeddedViewRef<any>).rootNodes;
		this.setProperties();
		document.body.appendChild(domElem);
	}

	/**
	 * Set the properties of the dropdown component
	 * @description
	 * 1. Set the width of the dropdown component
	 * 2. Set the template of the dropdown component
	 * @returns void
	 */
	setProperties() {
		if (this._dropdownRef && this.dropdownContent) {
			this.setPosition();
			this._dropdownRef.instance.template = this.dropdownContent.template;
		}
	}

	/**
	 * Set the position of the dropdown component
	 * @description
	 * Gets the width of the host element and sets it to the dropdown component
	 * @returns void
	 */
	setPosition() {
		if (this._dropdownRef && this.dropdownTrigger) {
			const { left, bottom, top, right } =
				this.dropdownTrigger.elementRef.nativeElement.getBoundingClientRect();

			if (!this.fullWidth) {
				this._dropdownRef.instance.width =
					this.dropdownTrigger.elementRef.nativeElement.offsetWidth;
			}

			this._dropdownRef.instance.triggerTop = top;

			switch (this.dropdownPosition) {
				case 'top':
					this._dropdownRef.instance.bottom = window.innerHeight + 11;
					this._dropdownRef.instance.left = left - 1;
					break;
				case 'bottom':
					this._dropdownRef.instance.top = bottom + 11;
					this._dropdownRef.instance.left = left - 1;
					break;
				case 'left':
					this._dropdownRef.instance.top = top + 8;
					this._dropdownRef.instance.right = window.innerWidth - left + 4;
					break;
				case 'right':
					this._dropdownRef.instance.top = top + 8;
					this._dropdownRef.instance.left = right + 4;
					break;
				default:
					// Default to bottom if position is not specified
					this._dropdownRef.instance.top = bottom + 11;
					this._dropdownRef.instance.left = left - 1;
			}

			this._dropdownRef.instance.cd.markForCheck();
		}
	}

	@HostListener('window:resize')
	onResize() {
		this.setPosition();
	}

	/**
	 * Destroy the dropdown component
	 * @description
	 * 1. Detach the dropdown component from the applicationRef
	 * 2. Destroy the dropdown component
	 * @returns void
	 */
	public destroyDropdown() {
		if (this._dropdownRef) {
			this._dropdownRef.instance.close();
			/**
			 * Wait for the animation to finish before destroying the component.
			 */
			setTimeout(() => {
				if (this._dropdownRef) {
					this.applicationRef.detachView(this._dropdownRef.hostView);
					this._dropdownRef.destroy();
					this._dropdownRef = null;
				}
			}, 300);
		}
	}

	/**
	 * Close the dropdown component
	 * @description
	 * 1. Set the dropdown open control to false if the dropdown is open
	 * and the user clicks outside of the dropdown (closeOnOutsideClick must be true)
	 * @returns void
	 */
	@HostListener('document:click', ['$event.target'])
	clickOut(target: any) {
		if (!this._dropdownOpenControl.value) {
			return;
		} else if (target.classList.contains('ui-dropdown')) {
			this.closeDropdown();
		}
	}

	@HostListener('document:keydown.escape')
	onEscape() {
		if (this._dropdownOpenControl.value) {
			this._dropdownOpenControl.setValue(false);
		}
	}

	/**
	 * In use by external components
	 * such as the select component
	 * or maybe pass the formControl?
	 */
	toggleDropdown(): void {
		this._dropdownOpenControl.setValue(!this._dropdownOpenControl.value);
	}

	/**
	 * Should be used by external components to open the dropdown
	 * Will not trigger the clickOutside functionality!
	 */
	openDropdown(): void {
		this._dropdownOpenControl.setValue(true);
	}

	closeDropdown(): void {
		this._dropdownOpenControl.setValue(false);
	}

	getState(): Observable<any> {
		return this._dropdownOpenControl.valueChanges;
	}

	ngOnDestroy(): void {
		this.destroyDropdown();
		this._dropdownOpenControlSubscription$?.unsubscribe();
		this.clickListenerSubscription?.unsubscribe();
	}
}
