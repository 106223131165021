<div
	class="ui-box"
	[ngClass]="{ 'ui-box--action': action }"
	[class.is-disabled]="disabled"
	(click)="onBoxClicked()"
>
	<div
		class="ui-box__banner"
		[class.has-tag]="tagText"
		[style.background-image]="image && fullSizeImage ? 'url(' + image + ')' : ''"
		[class.ui-box__banner--fullSizeImage]="fullSizeImage"
	>
		@if (tagText) {
			<ui-tag
				class="ui-box__tagContainer"
				[text]="tagText"
				[icon]="tagIcon"
				[type]="tagType"
			></ui-tag>
		}

		@if (image && !fullSizeImage) {
			<div class="ui-box__imageContainer" [class.has-tag]="tagText">
				<img class="ui-box__image" [src]="image" alt="Box image" />
			</div>
		}
	</div>

	<div class="ui-box__content" [ngStyle]="{ 'background-color': color?.background }">
		<ng-content></ng-content>
	</div>
</div>
