import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDeAt from '@angular/common/locales/de-AT';
import localeDe from '@angular/common/locales/de';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';

export class TranslationsLoader implements TranslateLoader {
	constructor(private http: HttpClient) {}

	getTranslation(langCountry: string): Observable<any> {
		langCountry = langCountry.toLowerCase();

		if (langCountry === 'de') {
			registerLocaleData(localeDeAt);
			registerLocaleData(localeDe);
		}
		const subject = new Subject();
		combineLatest([
			this.http.get('/assets/i18n/' + langCountry + '.json?cb=' + Date.now()),
			this.http.get('/assets/i18n/' + langCountry + '-general.json?cb=' + Date.now()),
		]).subscribe({
			next: (arr: Array<any>) => {
				let object = {};
				Object.keys(arr).forEach((objKey: any) => {
					const array = arr[objKey];
					Object.keys(array).forEach((key: any) => {
						// checks if translation key is duplicated
						if (object.hasOwnProperty(key)) {
							// adds the translation from the general file
							(object as any)[key] = Object.assign((object as any)[key], array[key]);
						} else {
							object = Object.assign(object, { [key]: array[key] });
						}
					});
				});
				subject.next(object);
				subject.complete();
			},
		});
		return subject.asObservable();
	}
}
