<div class="ui-datepicker-calendar">
	@for (weekDay of weekDays; track weekDay) {
		<div class="ui-datepicker-calendar__weekDay">
			{{ 'datepicker.weekday.' + weekDay | translate }}
		</div>
	}
	@for (day of days; track day.date) {
		<div
			class="ui-datepicker-calendar__day"
			[class.is-inactive]="disabled || day.disabled || day.notInActiveMonth"
			[class.is-disabled]="day.disabled || disabled"
			[class.is-selected]="isDateSelected(day)"
			[class.is-hovered]="isHovered(day)"
			(click)="selectDay(day)"
			(mouseenter)="hoverDay(day)"
			(mouseleave)="unhoverDay()"
			[uiTooltip]="(day.disabled ? 'datepicker.disabled' : '') | translate"
		>
			{{ day.date | date: 'dd' }}
		</div>
	}
</div>
